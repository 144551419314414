<template>
  <b-form class="auth-register-form" @submit.prevent="submitForm">
    <!-- label_departement= {{ label_departement }} -->
    <b-row>
      <b-col v-if="canShowField('departement')" md="12" lg="6">
        <!-- departement -->
        <b-form-group :label="label_departement" label-for="departement">
          <template v-slot:label>
            <span>{{ label_departement }}
              <RequiredStar />
            </span>
          </template>
          <!-- <b-form-select v-model="form.departement">
            <template #first>

                value=""
                disabled
              >
                Veuillez sélectionner un departement
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="(item, index) in departements"
              :key="index"
              :value="item.name || ''"
            >
              {{ item.name || item }}
            </b-form-select-option>
          </b-form-select> -->
          <v-select v-model="form.departement" type="text" :options="departements" label="nom" required="true"
            placeholder="Veuillez sélectionner un département" :class="$v.form.departement.$error ? 'invalid-input' : ''"
            :reduce="(item) => item.nom || item" @close="$v.form.departement.$touch" />
          <div v-if="$v.form.departement.$error">
            <small v-if="$v.form.departement.required.$invalid" class="text-danger">Ce champ est requis</small>
          </div>
        </b-form-group>
      </b-col>
      <b-col v-if="canShowField('commune')" md="12" lg="6">
        <!-- communes -->
        <b-form-group :label="label_commune" label-for="communes">
          <template v-slot:label>
            <span>{{ label_commune }}
              <RequiredStar />
            </span>
          </template>
          <!-- <b-form-select v-model="form.commune">
            <template #first>
              <b-form-select-option
                value=""
                disabled
              >
                Veuillez sélectionner une commune
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="(item, index) in communes"
              :key="index"
              :value="item.name || ''"
            >
              {{ item.name || item }}
            </b-form-select-option>
          </b-form-select> -->
          <v-select :disabled="!form.departement" v-model="form.commune" type="text" :options="communes" label="nom"
            required="true" placeholder="Veuillez sélectionner une commune" :loading="isLoadingCommunes"
            :reduce="(item) => item.nom || item" :class="$v.form.commune.$error ? 'invalid-input' : ''"
            @close="$v.form.commune.$touch" />
          <div v-if="$v.form.commune.$error">
            <small v-if="$v.form.commune.required.$invalid" class="text-danger">Ce champ est requis</small>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="canShowField('arrondissement')" md="12" :lg="breakPoints.cols.lg || '6'">
        <!-- arrondissements -->
        <b-form-group :label="label_arrondissement" label-for="arrondissements">
          <template v-slot:label>
            <span>{{ label_arrondissement }}
              <RequiredStar />
            </span>
          </template>
          <!-- <b-form-select v-model="form.arrondissement">
            <template #first>
              <b-form-select-option
                value=""
                disabled
              >
                Veuillez sélectionner un arrondissement
              </b-form-select-option>
            </template>
            <b-form-select-option
              v-for="(item, index) in arrondissements"
              :key="index"
              :value="item.name || ''"
            >
              {{ item.name || item }}
            </b-form-select-option>
          </b-form-select> -->
          <v-select :disabled="!form.commune" v-model="form.arrondissement" type="text" :options="arrondissements"
            label="nom" required="true" placeholder="Veuillez sélectionner un arrondissement"
            :loading="isLoadingArrondissements" :reduce="(item) => item.nom || item"
            :class="$v.form.arrondissement.$error ? 'invalid-input' : ''" @close="$v.form.arrondissement.$touch" />
          <div v-if="$v.form.arrondissement.$error">
            <small v-if="$v.form.arrondissement.required.$invalid" class="text-danger">Ce champ est requis</small>
          </div>
        </b-form-group>
      </b-col>
      <b-col v-if="canShowField('qtier')" md="12" lg="6">
        <!-- qtier -->
        <b-form-group :label="label_district" label-for="qtier">
          <template v-slot:label>
            <span>{{ label_district }}
              <RequiredStar />
            </span>
          </template>
          <v-select :disabled="!form.commune" v-model="form.qtier" type="text" :options="quartiers" label="nom"
            required="true" placeholder="Veuillez sélectionner un quartier" :loading="isLoadingQuartiers"
            :reduce="(item) => item.nom || item" :class="$v.form.qtier.$error ? 'invalid-input' : ''"
            @close="$v.form.qtier.$touch" />
          <!-- <b-form-input
            :disabled="!form.arrondissement"
            v-model="form.qtier"
            :state="$v.form.qtier.$error ? false : null"
            @blur="$v.form.qtier.$touch"
          /> -->
          <!-- <v-select
            type="text"
            :options="quartiers"
            label="name"
            required="true"
          /> -->
          <div v-if="$v.form.qtier.$error">
            <small v-if="$v.form.qtier.required.$invalid" class="text-danger">Ce champ est requis</small>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import AppLogo from "@/components/AppLogo.vue";
import RecaptchaWidget from "@/components/RecaptchaWidget.vue";
import RequiredStar from "@/components/RequiredStar.vue";
import localstorageService from "@/services/localstorage/localstorage.service";
import passwordValidators from "@/helpers/password-validators";

// store modules and vuex utilities
import paramsStoreModule from "@/store/params";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    RecaptchaWidget,
    RequiredStar,
    BSpinner,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
  },
  mixins: [togglePasswordVisibility],
  props: {
    triggerFormValidations: {
      type: Boolean,
      default: false,
    },
    providedLabels: {
      type: Object,
      default: () => ({
        // departement: "Département (Obligatoire)",
        // commune: "Commune (Obligatoire)",
        // arrondissement: "Arrondissement (Obligatoire)",
        // qtier: "Quartier/village (Obligatoire)",
      }),
    },
    breakPoints: {
      type: Object,
      default: () => ({
        cols: {
          lg: "6",
        },
      }),
    },

    initialValues: {
      type: Object,
      default: () => ({
        departement: "",
        commune: "",
        arrondissement: "",
        qtier: "",
      }),
    },
    canUpdateChildOnParentChanges: {
      type: Object,
      default: () => ({
        departement: true,
        commune: true,
        arrondissement: true,
        qtier: true,
      }),
    },
    activeFields: {
      type: Array,
      default: () => ["departement", "commune", "arrondissement", "qtier"],
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: "params", module: paramsStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return {
      requiredStoreModules,
      passwordValidators,
      $v: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      form: {
        departement: "",
        commune: "",
        arrondissement: "",
        qtier: "",
      },
      inputsLabels: {
        departement: "",
        commune: "",
        arrondissement: "",
        qtier: "",
      },
      isLoading: false,
      isLoadingDepartement: false,
      isLoadingCommunes: false,
      isLoadingArrondissements: false,
      isLoadingQuartiers: false,
    };
  },
  computed: {
    label_departement() {
      return (
        this.providedLabels.departement ||
        this.$t("component_localisation.department")
      );
    },
    label_commune() {
      return (
        this.providedLabels.commune || this.$t("component_localisation.commune")
      );
    },
    label_arrondissement() {
      return (
        this.providedLabels.arrondissement ||
        this.$t("component_localisation.arrondissement")
      );
    },
    label_district() {
      return (
        this.providedLabels.qtier || this.$t("component_localisation.district")
      );
    },
  },
  watch: {
    form: {
      // immediate: true,
      deep: true,
      handler(val, old) {
        const fields = ["departement", "commune", "arrondissement", "qtier"];
        const result = {};
        fields.forEach((field) => {
          if (this.canShowField(field)) result[field] = val[field];
        });
        this.$emit("input", result);
      },
    },
    providedLabels: {
      immediate: true,
      deep: true,
      handler(val, old) {
        this.inputsLabels = { ...this.inputsLabels, ...val };
      },
    },
    triggerFormValidations: {
      handler(val, old) {
        if (val === true) this.submitForm();
      },
    },
    "form.departement": {
      handler(val, old) {
        if (!this.canUpdateChildOnParentChanges.departement) return;
        // if (this.initialValues && this.initialValues.departement === this.form.departement) return;
        (this.form.commune = ""), (this.communes = []);
        this.loadCommunes();
      },
    },
    "form.commune": {
      handler(val, old) {
        if (!this.canUpdateChildOnParentChanges.commune) return;
        if (
          this.initialValues &&
          this.form.commune === "" &&
          this.initialValues.commune === this.form.commune
        )
          return;
        (this.form.arrondissement = ""), (this.arrondissements = []);
        this.loadArrondissements();
      },
    },
    "form.arrondissement": {
      handler(val, old) {
        if (!this.canUpdateChildOnParentChanges.qtier) return;
        if (
          this.initialValues &&
          this.form.arrondissement === "" &&
          this.initialValues.arrondissement === this.form.arrondissement
        )
          return;
        (this.form.qtier = ""), (this.quartiers = []);
        this.loadNeighborhoods();
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  validations() {
    return {
      form: {
        departement: { required },
        commune: { required },
        arrondissement: { required },
        qtier: { required },
      },
    };
  },
  mounted() {
    this.loadDepartments();
    // this.loadCommunes();
    // this.loadArrondissements();
    // this.loadNeighborhoods();
    this.form = JSON.parse(JSON.stringify(this.initialValues));
  },
  methods: {
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    // ------------------------------------------
    canShowField(field) {
      return this.activeFields.includes(field);
    },
    loadDepartments() {
      this.action_loadDepartments()
        .then((res) => {
          this.departements = res;
        })
        .catch((err) => { });
    },
    loadCommunes() {
      this.isLoadingCommunes = true;
      const query = { nom: this.form.departement };
      this.action_loadCommunes(query)
        .then((res) => {
          this.communes = [...new Set(res.map((e) => e.nom || e) || [])].map(
            (e) => ({ nom: e })
          );
          this.isLoadingCommunes = false;
        })
        .catch((err) => {
          this.isLoadingCommunes = false;
        });
    },
    loadArrondissements() {
      if (this.form.commune === "") return;
      const query = { nom: this.form.commune };
      this.isLoadingArrondissements = true;
      this.action_loadArrondissements(query)
        .then((res) => {
          this.arrondissements = [
            ...new Set(res.map((e) => e.nom || e) || []),
          ].map((e) => ({ nom: e }));
          this.isLoadingArrondissements = false;
        })
        .catch((err) => {
          this.isLoadingArrondissements = false;
        });
    },
    loadNeighborhoods() {
      // const arrondissement = { nom: this.form.arrondissement };
      // const commune = { nom: this.form.commune}
      const data = {commune: this.form.commune, arrondissement: this.form.arrondissement}
      this.action_loadNeighborhoods(data)
        .then((res) => {
          this.quartiers = [...new Set(res.map((e) => e.nom || e) || [])].map(
            (e) => ({ nom: e })
          );
        })
        .catch((err) => { });
    },
    async submitForm() {
      const isFormCorrect = await this.$v.$validate();
    },
  },
};
</script>

<style lang="scss">
.vs__actions {
  .vs__spinner {
    border-right: 0.9em solid #ff9f4300;
    border-left: 0.9em solid #0e6258;
    animation: vSelectSpinner-ltr 0.5s infinite linear !important;
  }
}
</style>
