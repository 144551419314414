<template>
  <div>
    <b-card class="mb-1 d-flex">
      <div class="d-flex flex-wrap justify-content-between">
        <div>
          <h2>Détails du commissariat</h2>
        </div>
        <div
          v-if="isAdminOrAgentMTCA"
          class="d-flex flex-wrap justify-content-between"
        >
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.modal-center
              class="mx-1"
              variant="primary"
            >
              <feather-icon icon="EditIcon" />
              Éditer
              <span class="text-nowrap" />
            </b-button>
          </div>

          <div>
            <ButtonArchiverUtilisateur
              v-if="agentId"
              :utilisateur-id="agentId"
              redirect-route="commissariats-list"
            />
          </div>
        </div>
      </div>
      <div class="text-right">
        <b-modal
          id="modal-center"
          centered
          size="lg"
          hide-footer
          no-close-on-backdrop
        >
          <EditCommissariat
            :redirect-after-edition-success="false"
            :initials-values="itemDetails"
          />
          <!-- <b-card-text>
            Croissant jelly-o halvah chocolate sesame snaps.
            Brownie caramels candy canes chocolate cake marshmallow icing lollipop I love.
            Gummies macaroon donut caramels biscuit topping danish.
          </b-card-text> -->
        </b-modal>
      </div>
      <div v-if="false" class="text-right">
        <ButtonArchiver :agent-etablissement-id="agentId" />
      </div>
    </b-card>
    <h1 v-if="!isFetchingAgentDetails && !itemDetails">
      Aucun commissariat ne correspond à cet enregistrement
    </h1>
    <content-loader
      v-if="isFetchingAgentDetails"
      view-box="0 0 400 460"
      :speed="2"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <circle cx="29" cy="30" r="17" />
      <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
      <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
      <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
    </content-loader>
    <b-card v-if="itemDetails" class="mb-0">
      <!-- infos détails -->
      <b-row>
        <b-col sm="12">
          <div class="row">
            <div class="col-md-6">
              <p><strong>Nom : </strong>{{ itemDetails.nom }}</p>
              <p>
                <strong>Département : </strong>{{ itemDetails.departement }}
              </p>
              <p><strong>Email : </strong>{{ itemDetails.email }}</p>
            </div>
            <div class="col-md-6">
              <p><strong>Adresse : </strong>{{ itemDetails.adresse }}</p>
              <p><strong>Commune : </strong>{{ itemDetails.commune }}</p>
              <p><strong>Téléphone : </strong>{{ itemDetails.telephone }}</p>
            </div>
          </div>
        </b-col>
      </b-row>
      <hr class="my-1" />

      <!-- infos agents  -->
      <ListAgentPoliceComponent :commissariat-id="itemDetails._id" />
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BModal,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
  BBadge,
  BTable,
  VBTooltip,
  VBModal,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useVuelidate from "@vuelidate/core";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import AppLogo from "@/components/AppLogo.vue";
import RecaptchaWidget from "@/components/RecaptchaWidget.vue";
import { ContentLoader } from "vue-content-loader";
import ButtonArchiver from "@/views/apps/utilisateurs/operateurs/agents/components/ButtonArchiver.vue";
import Ripple from "vue-ripple-directive";
import ButtonArchiverUtilisateur from "@/views/apps/utilisateurs/administration/component/ButtonArchiverUtilisateur.vue";
// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import usersStoreModule from "@/store/users";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";
import ListAgentPoliceComponent from "@/views/apps/utilisateurs/commissariat/components/ListAgentPoliceComponent.vue";
import utilsService from "@/services/utils/utils.service";
import EditCommissariat from "./EditCommissariat.vue";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "users", module: usersStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true }) };
  },
  components: {
    // BSV
    BCard,
    BModal,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    BBadge,
    BTable,

    // validations
    AppLogo,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    ContentLoader,
    ListAgentPoliceComponent,
    ButtonArchiver,
    EditCommissariat,

    ButtonArchiverUtilisateur,
  },

  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      itemDetails: null,
      forms: [],
      isFetchingAgentDetails: false,
      isLoadingTableData: false,
      loadingAddAgent: false,
      tableColumns: [
        { key: "_id", label: "#", sortable: true },
        { key: "reference", label: "Référence", sortable: true },
        { key: "createdAt", label: "Date", sortable: true },
        { key: "nomEtablissement", label: "Etablissement", sortable: true },
        { key: "statut", label: "Statut", sortable: true },
        { key: "actions" },
      ],
    };
  },
  computed: {
    etablissementDetails() {
      return this.itemDetails && this.itemDetails.etablissement;
    },
    agentId() {
      return this.itemDetails && this.itemDetails._id;
    },
    isAdminOrAgentMTCA() {
      const { currentUserId, userRole } = utilsService.currentUserUtils();
      return userRole.code === "agent_mtca" || userRole.code === "super_admin";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        this.isFetchingAgentDetails = true;
        const { agentId } = val.params;
        this.isLoadingTableData = true;
        this.getAgent(agentId)
          .then((res) => {
            const { resource, formsCreate } = res.data || {};
            this.itemDetails = resource;
            if (formsCreate) {
              formsCreate.map((item) => {
                this.forms = this.forms.concat(item.data);
              });
            }
            this.isFetchingAgentDetails = false;
            this.isLoadingTableData = false;
          })
          .catch((err) => {
            this.isFetchingAgentDetails = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.message || "Une erreur est survenue",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            console.log(err);
          });
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {},
  methods: {
    ...mapActions("etablissements", {
      getEtablissement: "getEtablissement",
      getAgent: "getAgent",
    }),
  },
};
</script>

<style lang="scss" scoped>
.img-ctn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  .img {
    height: 100px;
    width: 100px;
    background: rgba(4, 4, 4, 0.583);
    border-radius: 5px;
  }
}
</style>
