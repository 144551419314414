<template>
  <div>
    <!-- Section - Informations sur le commissariat -->
    <b-card>
      <b-card-body>

        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom du commissariat"
              label-for="nomCommissariat"
            >
              <b-form-input
                id="nomCommissariat"
                v-model="form.nom"
                name="Nom du commissariat"
              />
              <div v-if="$v.form.nom.$error">
                <small
                  v-if="$v.form.nom.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Adresse du commissariat"
              label-for="adresseCommissariat"
            >
              <b-form-input
                id="adresseCommissariat"
                v-model="form.adresse"
                name="Adresse du commissariat"
              />
              <div v-if="$v.form.adresse.$error">
                <small
                  v-if="$v.form.adresse.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <!-- Email du commissariat -->
            <b-form-group
              label="Email du commissariat"
              label-for="emailCommissariat"
            >
              <b-form-input
                id="emailCommissariat"
                v-model="form.email"
                name="Email du commissariat"
              />
              <div v-if="$v.form.email.$error">
                <small
                  v-if="$v.form.email.emailValidator.$invalid"
                  class="text-danger"
                >Adresse email invalide</small>
                <small
                  v-if="$v.form.email.required.$invalid"
                  class="text-danger"
                >Ce champ est requis</small>
              </div>
            </b-form-group>

          </b-col>
          <b-col md="6">
            <b-form-group
              label="Téléphone du commissariat"
              label-for="telephoneCommissariat"
            >
              <b-form-input
                id="telephoneCommissariat"
                v-model="form.telephone"
                v-phonenumber-field

                name="Téléphone du commissariat"
              />
              <!-- <div v-if="$v.form.telephone.$error">
                    <small
                      v-if="$v.form.telephone.required.$invalid"
                      class="text-danger"
                    >Ce champ est requis</small>
                  </div> -->
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <DepartementCommuneArrondissementVillageForm
              :trigger-form-validations="triggerDepartementFormValidations"
              :initial-values="form"
              :active-fields="['departement', 'commune', 'arrondissement']"
              :break-points="departementFormProps.breakPoints"
              :provided-labels="departementFormProps.departementFormLabels"
              :can-update-child-on-parent-changes="
                departementFormProps.canUpdateChildOnParentChanges
              "
              @input="onDepartementFormChange"
            />
          </b-col>
        </b-row>
        <b-row>
          <!-- password -->
          <b-col
            md="12"
            lg="6"
          >
            <b-form-group
              label-for="register-password"
              label="Mot de passe"
              class="password-field"
            >
              <b-input-group
                class="input-group-merge"
                :class="$v.form.password.$error ? 'is-invalid' : null"
              >
                <b-form-input
                  id="register-password"
                  v-model="form.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="$v.form.password.$error ? false : null"
                  name="register-password"
                  placeholder="············"
                  @focus="passwordFieldIsFocused = true"
                  @blur="passwordFieldIsFocused = false; $v.form.password.$touch"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="
                      passwordFieldType === 'password'
                        ? 'EyeIcon'
                        : 'EyeOffIcon'
                    "
                    class="cursor-pointer"
                    @click="togglePasswordInputType"
                  />
                </b-input-group-append>
              </b-input-group>
              <div
                v-if="passwordFieldIsFocused || $v.form.password.$error"
                class="invalid-feedback password-rules"
              >
                <small
                  v-if="
                    passwordFieldIsFocused || $v.form.password.isValid.$invalid
                  "
                >
                  <span class="label"> Le mot de passe doit contenir :</span>
                  <ul class="list-style-none password-validators-errors">
                    <li
                      :class="{
                        valid: passwordValidators(form.password)
                          .atLeastOneCapLetter,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password)
                              .atLeastOneCapLetter
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="
                            passwordValidators(form.password)
                              .atLeastOneCapLetter
                          "
                          class="las la-check-circle"
                        />
                      </div>
                      au moins une lettre majuscule
                    </li>
                    <li
                      :class="{
                        valid: passwordValidators(form.password)
                          .atLeastOneMinusLetter,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password)
                              .atLeastOneMinusLetter
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="
                            passwordValidators(form.password)
                              .atLeastOneMinusLetter
                          "
                          class="las la-check-circle"
                        />
                      </div>
                      au moins une lettre minuscule
                    </li>
                    <li
                      :class="{
                        valid: passwordValidators(form.password)
                          .atLeastOneDigits,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password).atLeastOneDigits
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="
                            passwordValidators(form.password).atLeastOneDigits
                          "
                          class="las la-check-circle"
                        />
                      </div>
                      au moins un chiffre
                    </li>
                    <li
                      :class="{
                        valid: passwordValidators(form.password)
                          .atLeastOneSpecialChar,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password)
                              .atLeastOneSpecialChar
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="
                            passwordValidators(form.password)
                              .atLeastOneSpecialChar
                          "
                          class="las la-check-circle"
                        />
                      </div>
                      au moins un caractère special (@#$%^&+=)
                    </li>
                    <li
                      :class="{
                        valid: passwordValidators(form.password).minimumLength,
                      }"
                    >
                      <div class="icons">
                        <i
                          v-if="
                            !passwordValidators(form.password).minimumLength
                          "
                          class="las la-times-circle"
                        />
                        <i
                          v-if="passwordValidators(form.password).minimumLength"
                          class="las la-check-circle"
                        />
                      </div>
                      au moins 8 caractères
                    </li>
                  </ul>
                </small>
              </div>
            </b-form-group>
          </b-col>
          <!-- confirm password -->
          <b-col
            md="12"
            lg="6"
          >
            <b-form-group
              label-for="register-password"
              label="Confirmer le mot de passe"
            >
              <b-input-group
                class="input-group-merge"
                :class="$v.form.confirmPassword.$error ? 'is-invalid' : null"
              >
                <b-form-input
                  id="register-password"
                  v-model="form.confirmPassword"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="$v.form.confirmPassword.$error ? false : null"
                  name="register-password"
                  placeholder="············"
                  @blur="$v.form.confirmPassword.$touch"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="
                      passwordFieldType === 'password'
                        ? 'EyeIcon'
                        : 'EyeOffIcon'
                    "
                    class="cursor-pointer"
                    @click="togglePasswordInputType"
                  />
                </b-input-group-append>
              </b-input-group>
              <div v-if="$v.form.confirmPassword.$error">
                <small
                  v-if="$v.form.confirmPassword.isValid.$invalid"
                  class="text-danger"
                >Les mots de passe ne sont pas identiques</small>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email as emailValidator } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import DepartementCommuneArrondissementVillageForm from '@/components/DepartementCommuneArrondissementVillageForm.vue'
import passwordValidators from '@/helpers/password-validators'
// store module and vuex utilities
import commissaireStroreModule from '@/store/commissaire'
import rolesStroreModule from '@/store/roles'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    // BSV
    BRow,
    BCol,

    BCard,
    BCardHeader,
    BCardBody,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    // validations
    AppLogo,
    vSelect,
    RecaptchaWidget,
    BSpinner,
    ToastificationContent,
    DepartementCommuneArrondissementVillageForm,
  },
  props: {
    initialsValues: {
      type: Object,
      default: () => ({
        nom: null,
        departement: null,
        arrondissement: null,
        commune: null,
        adresse: null,
        telephone: null,
        email: null,
        // password: null,
      }),
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: 'commissaire', module: commissaireStroreModule },
      { path: 'roles', module: rolesStroreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { 
      passwordValidators,
      requiredStoreModules, 
      $v: useVuelidate({ $lazy: true }) 
    }
  },
  data() {
    return {
      departementFormProps: {
        canUpdateChildOnParentChanges: {
          departement: true,
          commune: true,
        },
        departementFormLabels: {
          departement: 'Département',
          commune: 'Commune',
          arrondissement: 'Arrondissement',

        },
        breakPoints: { cols: { lg: '12' } },
      },
      triggerDepartementFormValidations: false,

      form: {
        nom: null,
        departement: null,
        arrondissement: null,
        commune: null,
        adresse: null,
        telephone: null,
        email: null,
        password: '',
        confirmPassword: '',
      },
      passwordFieldIsFocused: false,
      // confPassword: null,

      currentUserId: localstorageService.getUserId(),
      currentRole: null,

      // recaptchaResponse: null,
      passwordFieldType: 'password',
      isLoading: false,
      listEtablissements: [],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    initialsValues: {
      immediate: true,
      deep: true,
      handler(val, old) {
        this.form = { ...this.form, ...(val || {}) }
        console.log('val :>> ', this.form)
      },
    },
    passwordFieldIsFocused: {
      handler(val, old) {
        this.$v.form.password.$validate()
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },

  validations() {
    return {
      form: {
        nom: { required },
        departement: { required },
        arrondissement: { required },
        adresse: { required },
        // telephone: { required },
        commune: { required },
        // password: { required },
        email: { required, emailValidator },
        password: {
          required,
          isValid(value) {
            return this.passwordValidators(value).isValid
          },
        },
        confirmPassword: {
          isValid: val => val && val === this.form.password,
        },
      },
      // confPassword: { required },
      // recaptchaResponse: { isValid: val => val && val.type === 'success' },
      // isLoading: false,
      
    }
  },
  methods: {
    ...mapActions('commissaire', {
      createCommissaire: 'createCommissaire',
    }),
    ...mapActions('roles', { fetchRoles: 'fetchRoles' }),
    ...mapActions('imagePuzzleSliderCaptcha', { showSliderCaptcha: 'show' }),
    onDepartementFormChange(val) {
      this.form = { ...this.form, ...val }
    },
    togglePasswordInputType() {
      // eslint-disable-next-line no-unused-expressions
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    onRecaptchaChange(recaptchaEvent) {
      if (recaptchaEvent && recaptchaEvent.type === 'success') {
        this.recaptchaResponse = recaptchaEvent
      } else {
        this.recaptchaResponse = null
      }
    },
    async submitForm() {
      const isFormCorrect = await this.$v.$validate()
      this.triggerDepartementFormValidations = true
      console.log('this.value :>> ', this.value)
      this.$emit('input', this.form)
    },
    getRoleCommissaire() {
      const data = { options: { code: 'commissaire' } }
      this.fetchRoles(data)
        .then(res => {
          if (res.data && res.data.resources[0]) {
            this.form.role = res.data.resources[0]._id
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },

  mounted() {
    this.getRoleCommissaire()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
